import { fetchFromNewApi } from '~/helpers/api'

export const useProducts = () => {
  const add = async ({
    category,
    subcategory,
    item,
    query,
  }: {
    category: string | number
    subcategory: string | number
    item: string
    query: Record<string, any>
  }) => {
    try {
      return await fetchFromNewApi('/api/v1/bag/add', {
        method: 'POST',
        body: {
          ...getItemParams({ category, subcategory, item, query }),
          stock: query.stockNumber,
          discountCode: query['discount-code'],
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const getFinanceOptions = async ({ amount }) => {
    try {
      const response = await fetchFromNewApi(`/api/v1/payments/v12finance/finance-calculator`, {
        method: 'POST',
        body: {
          Amount: amount,
        },
      })

      return response || null
    } catch (error) {
      //ToDo: it should not work in that way, discuss with BE
      if (error.statusCode === 422) {
        return []
      }
    }
  }

  return {
    add,
    getFinanceOptions,
  }
}
